<template>
	<div class="com-plant-list">
		<a-space class="mb12" v-if="selection">
			<a-button @click="changePlantStatus('sau',pks)">播种</a-button>
			<a-button @click="changePlantStatus('xiao',pks)">幼苗</a-button>
			<a-button @click="changePlantStatus('up',pks)">生长</a-button>
			<com-plant-output-set
				:ids="pks" 
				@success="callbackFun"
			>
				<a-button>成熟</a-button>
			</com-plant-output-set>
			
			<a-button @click="changePlantStatus('complete',pks)">已完成</a-button>
			<a-button @click="changePlantStatus('withered',pks)">枯萎</a-button>
		</a-space>
		<a-table :pagination="false" row-key="id" :data-source="list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'种子名称',dataIndex:'name'},
			{title:'面积/价格',dataIndex:'area',slots:{customRender:'area'}},
			{title:'时间',dataIndex:'time',slots:{customRender:'time'}},
			{title:'当前状态',dataIndex:'status',slots:{customRender:'status'}},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" :row-selection="selection ? { selectedRowKeys: pks, onChange:(e)=>{pks = e}  } : null">
			<template #area="{record}">
				<div><span class="f12">面积/单价：</span>{{ record.price }}元/{{ record.area}}㎡</div>
				<div><span class="f12">总价单价：</span><span class="kdred">{{ record.area * record.price}}元</span></div>
			</template>
			<template #time="{record}">
				<div><span class="f12">播种时间：</span>{{ record.plant_time || '-'}}</div>
				<div><span class="f12">预计成熟：</span>{{record.ripe_time || '-'}}</div>
			</template>
			<template #status="{record}">
				<a-dropdown>
				    <a-tag :color="$status.getPlantStatusBg(record)">
				    	{{$status.getPlantStatus(record)}}
						<i class="ri-arrow-down-s-line"></i>
				    </a-tag>
				    <template #overlay>
						<a-menu>
							<a-menu-item @click="changePlantStatus('sau',[record.id])">播种</a-menu-item>
							<a-menu-item @click="changePlantStatus('xiao',[record.id])">幼苗</a-menu-item>
							<a-menu-item @click="changePlantStatus('up',[record.id])">生长</a-menu-item>
							<a-menu-item>
								<com-plant-output-set
									:ids="record.id" 
									@success="callbackFun"
								>成熟</com-plant-output-set>
							</a-menu-item>
							<a-menu-item @click="changePlantStatus('complete',[record.id])">完成</a-menu-item>
							<a-menu-item @click="changePlantStatus('withered',[record.id])">枯萎</a-menu-item>
						</a-menu>
				    </template>
				</a-dropdown>
			</template>
			<template #action="{record}">
				<a-space>
					<com-plant-status-add
						btn-size="small"
						btn-type="primary"
						:ids="record.id" 
						:land-id="record.land_id"
						@success="callbackFun()"
					>
					</com-plant-status-add>
					<a-button type="primary" size="small" @click="showStatus(record.id)">状态跟踪</a-button>
				</a-space>
			</template>
		</a-table>
		
		<a-modal v-model:visible="show.status" title="状态跟踪" :footer="null" @cancel="show.status = false" width="700px">
			<a-timeline>
			    <a-timeline-item v-for="(item,index) in statusList" :key="index">
					<div>{{item.description}}</div>
					<div class="cpl-imgs">
						<a-image style="width: 50px;height: 50px;margin-right: 12px;" 
						v-for="(val,ind) in item.src_show" :key='ind' :src="val" />
					</div>
					<a-space>
						<!-- <a-button size="small" type="link">编辑</a-button> -->
						<com-plant-status-add
							btn-size="small"
							btn-type="link"
							:ids="item.plant_id" 
							:land-id="item.land_id"
							:info="item"
							@success="showStatus(item.plant_id)"
						>
						</com-plant-status-add>
						<a-button size="small" type="link" @click="deleteStatus(item)">删除</a-button>
					</a-space>
				</a-timeline-item>
			</a-timeline>
		</a-modal>
	</div>
</template>
<script>
import comPlantStatusAdd from '@/components/layout/land/com-plant-status-add.vue'
import comPlantOutputSet from '@/components/layout/land/com-plant-output-set.vue'
import { reactive, toRefs } from 'vue'
import landModel from '@/api/land.js'
import common from '@/api/common.js'
export default{
	name:'com-plant-list',
	components:{
		comPlantStatusAdd,
		comPlantOutputSet
	},
	props:{
		list:{
			type:[Object,Array],
			default:[]
		},
		selection:{
			type:Boolean,
			default:true
		}
	},
	setup(props,context){
		const _d = reactive({
			show:{
				status:false,
			},
			statusList:[],
			pks:[],
		})
		const callbackFun = ()=>context.emit("success",true)
		const changePlantStatus = (op,id)=>landModel.setPlantStatus(op,id,()=>callbackFun())
		function showStatus(plant_id){
			_d.show.status = true
			landModel.getPlantStatus(plant_id,res=>_d.statusList = res)
		}
		const deleteStatus = (data)=>{
			common.deleteDataList(data.id,32,'确认删除该条信息吗').then(()=>showStatus(data.plant_id))
		}
		return{
			...toRefs(_d),
			changePlantStatus,
			callbackFun,
			showStatus,
			deleteStatus
		}
	},
}
</script>
<style lang="scss" scoped>
	.cpl-imgs{
		display: flex;
	}
</style>
